<template>
  <b-card>
    <b-media
      no-body
      class="mb-1"
    >
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            v-img
            rounded
            :src="accountForm.avatar_url"
            height="80"
          />
        </b-link>
        <!--/ image -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="selectFile()"
        >
          Upload Image
        </b-button>
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @change="changeProfile($event)"
        />
        <!--/ upload button -->
      </b-media-body>
    </b-media>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label=" Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="accountForm.name"

            trim
            placeholder="Name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="accountForm.email"

            trim
            placeholder="email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="password"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="accountForm.password"

            trim
            placeholder="password"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-if="!isLoading"
          variant="primary"
          class="mr-1"
          @click="addUser()"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="isLoading"
          variant="primary"
          disabled
          class="mr-1"
        >
          <b-spinner
            small
            type="grow"
          />
          Loading...
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    const accountForm = ref({
      name: '',
      email: '',
      password: '',
      image: '',
      avatar_url: '',
    })
    // eslint-disable-next-line camelcase
    const avatar_url = ref('')
    return {
      accountForm,
      avatar_url,
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.avatar_url = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.accountForm.avatar_url = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.avatar_url = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    getUser() {
      axios.get('admin/profile').then(res => {
        if (res.status === 200) {
          this.accountForm = res.data.data.admin
        }
      })
    },
    addUser() {
      try {
        this.isLoading = true
        const formData = new FormData()
        formData.append('name', this.accountForm.name)
        formData.append('email', this.accountForm.email)
        if (this.accountForm.password) {
          formData.append('password', this.accountForm.password)
        }
        formData.append('image', this.avatar_url)
        axios.post('admin/update_profile', formData).then(res => {
          if (res.status === 200) {
            store.commit('login/SET_USER_INFO', res.data.data.user)
            this.$toasted.show('Updated Successfully', {
              position: 'bottom-center',
              duration: 3000,

            })
            this.isLoading = false

            this.getUser()
          }
        })
      } catch (e) {
        // console.log(e)
      }
    },
  },
}
</script>

<style>

</style>
